.uploadBoxWrapper {
  background-color: #FFFFFF;
  border: 1px solid #2092FF;
  padding: 8rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadLinkText {
  color: #2092FF;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 20px;
}