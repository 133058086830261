.loginWrapper {
  max-width: 50%;
  display: flex;
  padding: 2rem;
  border: 1px solid #aaa;
  background-color: #FFF;
}

.loginBtn {
  background-color: #2c4e86;
  border-radius: 0;
  border-color: #264373;
  color: #FFF;
  font-weight: bold;
  padding: 4px 12px;
}