.emailWrapper {
  border: 1px solid #1084d9;
  font-size: 14px;
}

.titleWrapper {
  padding: 16px;
  background-color: #FFF;
}

.subWrapper {
  background-color: #f0f0f0;
  padding: 16px;
  border-top: 1px solid #b9b9b9;
}

.submitBtnWrapper {
  display: flex;
  justify-content: flex-end;
}

.submitBtn {
  padding: 6px 12px;
  margin-right: 16px;
}