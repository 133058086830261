$theme-colors: (
  "primary": #2092FF
);

@import '~bootstrap/scss/bootstrap.scss';
@import 'bootstrap-icons/font/bootstrap-icons.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
}

#sidebar {
  width: 4.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}

#app-content {
  margin-left: 4.5rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EFF6FF;
}

.ag-header-row-column-group {
  background-color: #2092FFbb;
}

.ag-header-row-column {
  background-color: #2092FF66;
}

.ag-header-group-cell:hover,
.ag-header-cell:hover {
  background-color: #2092FF !important;

  * {
    color: #FFF !important;
  }
}

.ag-react-container {
  width: 100%;
  height: 100%;
}

.nav-link {
  .menu-icon {
    font-size: 24px;
    color: #71839B;
  }

  &.active {
    background-color: #71839B !important;
    .menu-icon {
      color: white;
    }
  }
}

.upload-section {
  input {
    width: 400px;
    border: 0;
    border-bottom: 1px solid #999;
    padding: 12px;
    background-color: transparent;
    outline: 0;
    margin-right: 8px;
  }
}

.iframe-page {
  width: 100%;
  height: 100%;
}

div[data-tippy-root] {
  top: 10vh !important;
  left: 10vw !important;
  transform: none !important;
}