.statusWrapper {
  font-size: 20px;
  color: green;
  cursor: pointer;

  span {
    font-size: 14px;
    color: #000;
    margin-left: 8px;
  }
}

.statusBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mismatch {
  color: orangered;
}

.missing {
  color: rgb(255, 153, 0);
}
